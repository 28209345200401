:root {
  --background-color: #fff;
  --text-color: #000;
}

.dark-mode {
  --background-color: #1e1e1e;
  --text-color: #f5f5f5;
}

.theme-switch-wrapper {
  z-index: 10;
  margin: 10px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.theme-switch {
  width: 35px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.theme-switch input {
  display: none;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 17px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.slider i {
  color: #fff;
  transition: opacity .1s;
  position: absolute;
  transform: scale(.6);
}

.fa-sun {
  opacity: 0;
  top: 1px;
  left: 2px;
}

.fa-moon {
  opacity: 1;
  top: 1px;
  right: 2px;
}

input:checked + .slider {
  background-color: #06c;
}

input:checked + .slider .fa-sun {
  opacity: 1;
}

input:checked + .slider .fa-moon {
  opacity: 0;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

h1, h2, h3 {
  margin: 0;
  font-family: Unbounded, cursive;
  font-weight: 700;
  line-height: 1.25;
}

h3 {
  font-weight: 400;
}

a {
  color: #06c;
}

body.dark-mode, body.dark-mode .navbar, body.dark-mode .navbar a, body.dark-mode .navbar .navbar-item, body.dark-mode .card-content {
  color: #e0e0e0;
  background-color: #121212;
}

body.dark-mode .navbar a {
  color: #e0e0e0;
}

footer.dark-mode {
  color: #e0e0e0;
  background-color: #121212;
}

a.dark-mode {
  color: #06c;
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode strong, body.dark-mode .title {
  color: #e0e0e0;
}

body.dark-mode .content, body.dark-mode .control-group, body.dark-mode .menu-banner, body.dark-mode .card-body {
  color: #e0e0e0;
  background-color: #121212;
}

.sticky-banner {
  text-align: center;
  z-index: 1000;
  background-color: #b55f56;
  border-bottom: 5px solid #ddd;
  padding: 10px;
  position: sticky;
  top: 0;
}

.sticky-banner p {
  color: #fff;
  margin: 0;
}

.sticky-banner a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.spacer {
  height: 20px;
}

.sidebar {
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  width: 250px;
  height: 100vh;
  padding-top: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.sidebar-sticky {
  height: 100vh;
  padding-top: 1rem;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

.nav-link {
  color: #535353;
  margin-left: 10px;
}

.nav-link:hover {
  color: #e6007a;
  text-decoration: none;
}

.nav-link.h1 {
  font-family: Unbounded, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.nav-link.h2 {
  margin-left: 20px;
  font-family: Unbounded, sans-serif;
  font-size: .9rem;
}

.nav-link.h3 {
  margin-left: 25px;
  font-family: Unbounded, sans-serif;
  font-size: .75rem;
}

@media (width <= 991px) {
  .sidebar {
    display: none;
  }

  .sidebar-sticky {
    height: auto;
    position: static;
    overflow-y: visible;
  }
}

.field-label {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.field {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
  display: flex;
}

.radio {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.radio-input[type="radio"] {
  margin-right: 5px;
}

.radio-label {
  cursor: pointer;
  align-items: center;
  margin: 0;
  font-weight: bold;
  display: flex;
}

.radio-input[type="radio"] {
  appearance: none;
  cursor: pointer;
  border: 2px solid #e6007a;
  border-radius: 50%;
  outline: none;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.radio-input[type="radio"]:checked:after {
  content: "";
  background-color: #e6007a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 3px;
  display: block;
}

input[type="radio"]:after {
  content: "";
  visibility: visible;
  background-color: #d1d3d1;
  border: 2px solid #fff;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: -2px;
  left: -1px;
}

input[type="radio"]:checked:after {
  content: "";
  visibility: visible;
  background-color: #e6007a;
  border: 2px solid #fff;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: -2px;
  left: -1px;
}

.dropbtn {
  color: #fff;
  cursor: pointer;
  background-color: #e6007a;
  border: none;
  padding: 2px;
  font-size: 13px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  z-index: 1;
  background-color: #f9f9f9;
  min-width: 70px;
  display: none;
  position: absolute;
  box-shadow: 0 2px 2px #0006;
}

.dropdown-content a {
  color: #000;
  padding: 2px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  right: 0;
}

.dropdown:hover .dropbtn {
  background-color: #e6007a;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #381ff4;
  text-align: left;
  z-index: 1;
  background-color: #cbe3eb;
  border-radius: 6px;
  width: 620px;
  margin-left: -120px;
  padding: 1px 0;
  font-size: 14px;
  position: absolute;
  top: 100%;
  left: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.columns {
  justify-content: space-around align-items: stretch;
  flex-wrap: wrap;
  display: flex;
}

.card-title {
  margin-bottom: 10px;
  padding: 5px 20px;
  font-size: 24px;
  font-weight: bold;
}

.mt-2 {
  color: #f00a0a;
  opacity: .5;
  padding-bottom: 10px;
  padding-left: 20px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
}

table.tb {
  border-collapse: collapse;
  width: 100%;
}

table.tb th, table.tb td {
  padding: 2px;
}

table.tb td:first-child, table.tb th:first-child {
  text-align: left;
}

table.tb td:nth-child(2), table.tb th:nth-child(2) {
  text-align: right;
}

table.tb {
  border-collapse: collapse;
  border: 1px solid #f0f0f0;
}

table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

table tr:nth-child(2n) {
  background-color: #f4f4f4;
}

table.tb th {
  background-color: #ececec;
}

.styled-ink-table {
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 4px 8px #0000001a;
}

.styled-ink-table th, .styled-ink-table td {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.styled-ink-table tr:nth-child(2n) {
  background-color: #f2f2f7;
}

.styled-ink-table tr:hover {
  background-color: #ddd;
}

.styled-ink-table .available {
  color: #34c759;
}

.styled-ink-table .coming-soon {
  color: #ff3b30;
}

.styled-ink-table .na {
  color: #c7c7cc;
}

body.dark-mode .styled-ink-table {
  color: #ccc;
  background-color: #121212;
  border-color: #222;
}

body.dark-mode .styled-ink-table th, body.dark-mode .styled-ink-table td {
  color: #ccc;
  border-bottom: 1px solid #444;
}

body.dark-mode .styled-ink-table tr {
  background-color: #121212;
}

body.dark-mode .styled-ink-table tr:hover {
  background-color: #2a2a2a;
}

body.dark-mode .styled-ink-table .available {
  color: #4caf50;
}

body.dark-mode .styled-ink-table .coming-soon {
  color: #ff5722;
}

body.dark-mode .styled-ink-table .na {
  color: #757575;
}

body.dark-mode {
  min-height: 100vh;
}

.footer2 {
  text-align: justify;
  color: #000;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
}

.footer2 .content {
  max-width: 1100px;
  margin: 0 auto;
}

body.dark-mode .footer2 {
  color: #e0e0e0;
  background-color: #121212;
}

.referendum-list {
  margin-left: 20px;
  list-style-type: disc;
}

.referendum-list li {
  margin-bottom: 10px;
}
/*# sourceMappingURL=block_weights.d04f10fb.css.map */
