:root {
  --background-color: #fff;
  --text-color: #000;
}

.dark-mode {
  --background-color: #1e1e1e;
  --text-color: #f5f5f5;
}

/* Toggle Switch Style */
.theme-switch-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 10px;
  z-index: 10;
}

.theme-switch {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 20px;
}

.theme-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.slider i {
  color: #fff;
  position: absolute;
  transform: scale(0.6); 
  transition: opacity 0.1s;
}

.fa-sun {
  opacity: 0; 
  left: 2px; 
  top: 1px;
}

.fa-moon {
  right: 2px; 
  top: 1px;
  opacity: 1; 
}

input:checked + .slider {
  background-color: #0066CC;
}

input:checked + .slider .fa-sun {
  opacity: 1;
}

input:checked + .slider .fa-moon {
  opacity: 0;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

h1, h2, h3 {
  margin: 0;
  line-height: 1.25;
  font-weight: 700;
  font-family: 'Unbounded', cursive;
}

h3 {
  font-weight: 400;
}

/* Default light mode styles for links */
a {
  color: #0066CC; /* Blue color for all links */
}

/* Dark mode styles for body and generic text */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0; /* Light gray text for better readability in dark mode */
}

/* Specific dark mode styles for navbar and its elements */
body.dark-mode .navbar, body.dark-mode .navbar a, body.dark-mode .navbar .navbar-item , body.dark-mode .card-content {
  background-color: #121212; /* Dark background for navbar */
  color: #e0e0e0; /* Light gray text color for readability */
}

/* Ensuring link colors inside navbar also change */
body.dark-mode .navbar a {
  color: #e0e0e0; /* Ensures links inside navbar also get the light gray color */
}

footer.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

a.dark-mode {
  color: #0066CC;
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode strong , body.dark-mode .title {
  color: #e0e0e0;
}

/* Dark mode specific styles for source information */
body.dark-mode .content, body.dark-mode .control-group, body.dark-mode .menu-banner , body.dark-mode .card-body {
  background-color: #121212;
  color: #e0e0e0;
}

.sticky-banner {
    position: sticky; 
    top: 0;
    background-color: #b55f56; /* or any color you prefer */
    padding: 10px;
    text-align: center;
    border-bottom: 5px solid #ddd;
    z-index: 1000; /* Ensure it stays on top of other content */
}

.sticky-banner p {
    margin: 0;
    color: white;
}

.sticky-banner a {
    font-weight: bold;
    color: #ffffff; /* or any color you prefer */
    text-decoration: underline;
}

.spacer {
    height: 20px; /* Adjust this value to control the space */
  }

/* General Sidebar Styles */
.sidebar {
    background-color: #f8f9fa; /* Light gray background */
    border-right: 1px solid #dee2e6; /* Adds a border to the right */
    height: 100vh; /* Full height of the viewport */
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
    position: fixed; /* Keeps the sidebar fixed on the screen */
    top: 0; /* Aligns the top of the sidebar with the top of the viewport */
    left: 0; /* Aligns the sidebar on the left side of the viewport */
    width: 250px; /* Set the width of the sidebar */
    padding-top: 1rem; /* Adds padding at the top */
}

.sidebar-sticky {
    position: fixed;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    padding-top: 1rem;
}

/* Styling for Navigation Links */
.nav-link {
    color: #535353; /* Bootstrap primary color */
    margin-left: 10px; /* Indentation for better readability */
}

.nav-link:hover {
    color: #E6007A; /* Darker shade on hover */
    text-decoration: none;
}

/* Specific Styles for Different Header Levels */
.nav-link.h1 {
    font-size: 1rem;
    font-family: 'Unbounded', sans-serif;
    font-weight: 700;
}

.nav-link.h2 {
    font-size: 0.9rem;
    margin-left: 20px;
    font-family: 'Unbounded', sans-serif;
}

.nav-link.h3 {
    font-size: 0.75rem;
    margin-left: 25px;
    font-family: 'Unbounded', sans-serif;
}

/* Mobile Navigation Styles */
@media (max-width: 991px) {
  .sidebar {
    display: none;
  }

  .sidebar-sticky {
    position: static;
    height: auto;
    overflow-y: visible;
  }
}

/* For the Ecosystem radar buttons */
  .field-label {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .field {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; 
    justify-content: space-evenly;
  }
  
  .radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    
  }
  
  .radio-input[type="radio"] {
    margin-right: 5px;
    
  }
  
  .radio-label {
    font-weight: bold;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  /* Custom radio button styles */
  .radio-input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #E6007A; 
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
  }
  
  .radio-input[type="radio"]:checked::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px;
    background-color: #E6007A;
    border-radius: 50%;
  }
  
  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #E6007A;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: #E6007A;
  color: white;
  padding: 2px;
  font-size: 13px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 70px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,.4);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 2px 2px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  right: 0;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #E6007A;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text used for item descriptions*/
.tooltip .tooltiptext {
  visibility: hidden;
  width: 620px;
  top: 100%;
  left: 0%;
  margin-left: -120px;
  background-color: rgb(203, 227, 235);
  color: #381ff4;
  text-align: left;
  font-size:14px;
  padding: 1px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Adjustments for the card container */
.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
  align-items: stretch;
}

.card-title {
  padding: 5px 20px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.mt-2 {
  color: rgb(240, 10, 10);
  padding-left: 20px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: bold;
  opacity: 0.5
}

/* chart tooltip */
table.tb {
  width: 100%;
  border-collapse: collapse;
}

table.tb th, 
table.tb td {
  padding: 2px;
}

/* Apply left alignment to the first column */
table.tb td:first-child,
table.tb th:first-child {
  text-align: left;
}

/* Apply right alignment to the second column */
table.tb td:nth-child(2),
table.tb th:nth-child(2) {
  text-align: right;
}

/* Applying a very subtle border to the entire table */
table.tb {
  border: 1px solid #f0f0f0; /* Very light gray border */
  border-collapse: collapse; /* Ensures borders between cells are shared */
}

/* Odd rows - very light gray, slightly lighter than the even rows */
table tr:nth-child(odd) {
  background-color: #f9f9f9; /* Extremely light gray */
}

/* Even rows - very subtle gray, slightly darker to distinguish */
table tr:nth-child(even) {
  background-color: #f4f4f4; /* Slightly darker gray */
}

/* Table headers - a light gray that works with both backgrounds */
table.tb th {
  background-color: #ececec;
}

/* Ecosystem page Data Availablility Table formatting */
.styled-ink-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.styled-ink-table th, .styled-ink-table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.styled-ink-table tr:nth-child(even) {
  background-color: #f2f2f7;
}

.styled-ink-table tr:hover {
  background-color: #ddd;
}

.styled-ink-table .available {
  color: #34c759; 
}

.styled-ink-table .coming-soon {
  color: #ff3b30; 
}

.styled-ink-table .na {
  color: #c7c7cc; 
}
/* Dark theme styles */
body.dark-mode .styled-ink-table {
  background-color: #121212;
  color: #ccc;
  border-color: #222; /* Darker lines for better contrast */
}

body.dark-mode .styled-ink-table th, body.dark-mode .styled-ink-table td {
  border-bottom: 1px solid #444;
  color: #ccc
}

body.dark-mode .styled-ink-table tr {
  background-color: #121212;
}

body.dark-mode .styled-ink-table tr:hover {
  background-color: #2a2a2a;
}

body.dark-mode .styled-ink-table .available {
  color: #4CAF50;
}

body.dark-mode .styled-ink-table .coming-soon {
  color: #FF5722;
}

body.dark-mode .styled-ink-table .na {
  color: #757575;
}

body.dark-mode {
  min-height: 100vh;
}

.footer2 {
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  text-align: justify;
  background-color: #f8f9fa;
  color: #000;
}

.footer2 .content {
  margin: 0 auto;
  max-width: 1100px;
}


body.dark-mode .footer2 {
  background-color: #121212;
  color: #e0e0e0;
}

.referendum-list {
  list-style-type: disc; /* Adds the dot in front of the list items */
  margin-left: 20px; /* Indents the list */
}

.referendum-list li {
  margin-bottom: 10px; /* Adds space between list items */
}